import * as React from "react";
import { Button } from "react-bootstrap";
import { useProjectRecordQuery } from "../../clay/api";
import { Dictionary } from "../../clay/common";
import { useEditableContext } from "../../clay/edit-context";
import { Link } from "../../clay/link";
import { propCheck } from "../../clay/propCheck";
import { sumMap } from "../../clay/queryFuncs";
import { QuickCacheApi, useQuickCache } from "../../clay/quick-cache";
import { FormField, FormWrapper, Optional } from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { FieldRow } from "../../clay/widgets/layout";
import { StaticTextField, TextWidget } from "../../clay/widgets/TextWidget";
import { calcAddressLineFormatted } from "../address";
import AddressWidget from "../AddressWidget.widget";
import { COMPANY_META } from "../company/table";
import { CONTACT_META } from "../contact/table";
import { calcContingencyItemTotal } from "../contingency/table";
import { PROJECT_DESCRIPTION_CATEGORY_META } from "../project-description/table";
import { Role } from "../roles/table";
import { useUser } from "../state";
import {
    calcUserCodeName,
    ROLE_CERTIFIED_FOREMAN,
    ROLE_ESTIMATOR,
    ROLE_PROJECT_MANAGER,
    SQUAD_META,
    USER_META,
} from "../user/table";
import { DetailSheet, DETAIL_SHEET_META } from "./detail-sheet/table";
import { ExportData, NewExportButton } from "./NewExportButton";
import { PersonnelListWidget } from "./personnel/list-widget";
import ProjectDescriptionCategoryWidget from "./projectDescriptionDetail/ProjectDescriptionCategoryWidget.widget";
import { Project, PROJECT_META } from "./table";
import { usePhaseCodeExport } from "./overview/SpectrumExportWidget.widget";
import { useScheduleOfValuesTable } from "./overview/ScheduleOfValuesExportWidget.widget";

export type Data = Project;

export const Fields = {
    personnel: Optional(PersonnelListWidget),
    siteAddress: AddressWidget,
    customer: FormField(TextWidget),
    projectDescription: FormField(ProjectDescriptionCategoryWidget),
};

function buildProjectExport(
    project: Project,
    detailSheets: DetailSheet[] | undefined,
    cache: QuickCacheApi,
    phaseCodeExport: ExportData,
    scheduleOfValueExport: ExportData
): ExportData {
    const errors = [];
    const warnings = [];

    if (!detailSheets || detailSheets.length == 0) {
        errors.push("Missing Detail Sheets");
    }

    if (!project.projectDescription.category) {
        errors.push("Project has no squad");
    }

    const category = cache.get(
        PROJECT_DESCRIPTION_CATEGORY_META,
        project.projectDescription.category
    );
    if (!category) {
        errors.push("Project category is missing");
    }
    if (!category?.squad) {
        errors.push("Category has no squad");
    }

    const squad = cache.get(SQUAD_META, category?.squad ?? null);
    if (!squad) {
        errors.push("Could not find squad");
    }

    const roleToEmployeeCode = (role: Link<Role>) => {
        const entry = project.personnel.find((x) => x.role === role);
        if (!entry) {
            return "";
        }
        const user = cache.get(USER_META, entry.user);
        if (!user) {
            errors.push("Could not find user");
            return "";
        }
        if (user.spectrumEmployeeCode === "") {
            errors.push(`${user.name} does not have Spectrum Employee Code`);
        }
        return user.spectrumEmployeeCode;
    };

    const roleToInitials = (role: Link<Role>) => {
        const entry = project.personnel.find((x) => x.role === role);
        if (!entry) {
            return "";
        }
        const user = cache.get(USER_META, entry.user);
        if (!user) {
            errors.push("Could not find user");
            return "";
        }
        const parts = user.name.split(" ");
        return parts[0][0] + parts[parts.length - 1][0];
    };

    let customerCode = "";
    if (project.billingContacts.length > 0) {
        const contact = project.billingContacts[0];
        if (contact.company.company) {
            const company = cache.get(COMPANY_META, contact.company.company);
            if (!company) {
                errors.push("Company not found");
            } else if (company.spectrumCustomerCode) {
                customerCode = company.spectrumCustomerCode;
            } else {
                errors.push(
                    "Company " + company.name + " has no Spectrum Customer Code"
                );
            }
        } else {
            warnings.push("This a personal residence project");
            const theContact = cache.get(CONTACT_META, contact.contact);
            if (!theContact) {
                errors.push("Contact not found");
            } else if (theContact.spectrumCustomerCode) {
                customerCode = theContact.spectrumCustomerCode;
            } else {
                errors.push(
                    "Contact " +
                        theContact.name +
                        "  has no Spectrum Customer Code"
                );
            }
        }
    } else {
        errors.push("No billing contact");
    }

    let Original_Contract = "";

    if (detailSheets) {
        Original_Contract = sumMap(
            detailSheets.filter((x) => !x.change),
            (sheet) =>
                sumMap(sheet.schedules, (x) => x.price).plus(
                    sumMap(sheet.contingencyItems, calcContingencyItemTotal)
                )
        ).toString();
    }

    console.log("OK", Original_Contract);

    const spectrumProject = {
        Job_Number: "10" + project.projectNumber,
        Job_Description: calcAddressLineFormatted(project.siteAddress),
        Division: "",
        Address_1: project.siteAddress.line1,
        Address_2: "",
        City: project.siteAddress.city,
        State: project.siteAddress.province,
        Zip_Code: project.siteAddress.postal,
        Phone: "",
        Fax_Phone: "",
        Job_Site_Phone: "",
        Superintendent: roleToEmployeeCode(ROLE_CERTIFIED_FOREMAN),
        Estimator: roleToEmployeeCode(ROLE_ESTIMATOR),
        Project_Manager: roleToEmployeeCode(ROLE_PROJECT_MANAGER),
        Customer_Code: customerCode,
        Taxable_Flag: "N",
        Contract_Number: "",
        Job_Type: "",
        Tax_Class_Code: "",
        Total_Units: "",
        Unit_of_Measure: "",
        Certified_Flag: "",
        Work_Comp_Code: "721041",
        Wage_Rate_Level: "",
        Price_Method_Code: "U",
        Est_Start_Date: "",
        Est_Complete_Date: "",
        Start_Date: "",
        Complete_Date: "",
        Work_State_Tax_Code: "BC",
        Work_County_Tax_Code: "",
        Work_Local_Tax_Code: "",
        Original_Contract,
        WO_Site: "",
        Latitude: "",
        Longitude: "",
        Track_Davis_Bacon: "",
        Track_Prevailing_Wage: "",
        Create_Date: "",
        UDF: {},
        Cost_Center: squad?.spectrumCostCenter || "",
        Master_Job: "",
        Bill_From_Master: "",
        Standard_Phase_Category: "",
        Phase_Display_Code: "",
        Major_Group_Start: "",
        Major_Group_End: "",
        Minor_Group_Start: "",
        Minor_Group_End: "",
        Status_Code: "A",
    };

    const spectrumProjectUpdate = {
        ...spectrumProject,
        Owner_Name: "",
        Comment: "",
        Reports_Calc_Override: "",
        Entered_Percent_Complete: "",
        Entered_Projected_Cost: "",
        Legal_Desc: "",
        Job_Oh_Batch_Code: "",
        Routing_Code1: roleToInitials(ROLE_PROJECT_MANAGER),
        Routing_Code2: "",
        Routing_Limit: "",
        PO_Ship_Name: "",
        PO_Ship_Address1: "",
        PO_Ship_Address2: "",
        PO_Ship_City: "",
        PO_Ship_State: "",
        PO_Ship_Zip_Code: "",
        PO_Ship_Comment: "",
        PO_Ship_Country: "",
        Earned_Calc_Type: "",
        Under_Billed: "",
        Cost_Markup_Percent: "",
        Job_Pay_When_Paid: "",
        Job_Auto_Release_Percent: "",
        Job_Review_Percent: "",
        Auto_Ot_Flag: "",
        Certified_Project_ID: "",
        Burden_Flag: "",
        Pre_TC_Burden_Percent: "",
        Burden_Phase: "",
        Burden_Cost_Type: "",
        PR_OH_Amount: "",
        PR_OH_Type_Code: "",
        Payroll_OH_Percent: "",
        OH_Cost_Type: "",
        OH_Phase: "",
        Lock_Revenue_Entry: "",
        Certified_Week_Type: "",
        Override_By_Job: "",
        Daily_OT_Start: "",
        Weekly_OT_Start: "",
        Saturday_OT: "",
        Sunday_OT: "",
        Original_Contract: "",
    };

    return {
        errors: [...errors, ...phaseCodeExport.errors, ...scheduleOfValueExport.errors],
        warnings: [...warnings, ...phaseCodeExport.warnings, ...scheduleOfValueExport.warnings],
        requests: [{
            template: "jobCreate",
            request: spectrumProject
        }, {
            template: "jobUpdate",
            request: spectrumProjectUpdate
        }, ...phaseCodeExport.requests,
        ...scheduleOfValueExport.requests
    ]
    };
}

function Component(props: Props) {
    const cache = useQuickCache();
    const detailSheets = useProjectRecordQuery(
        DETAIL_SHEET_META,
        props.data.id.uuid
    );
    const user = useUser();
    const editable = useEditableContext();

    const phaseCodeExport = usePhaseCodeExport(props.data.id.uuid, true)
    const scheduleOfValuesExport = useScheduleOfValuesTable(props, true)

    const exportData = React.useMemo(
        () => buildProjectExport(props.data, detailSheets, cache, phaseCodeExport.exportData, scheduleOfValuesExport.data),
        [props.data, detailSheets, cache, phaseCodeExport.exportData, scheduleOfValuesExport.data]
    );

    return (
        <>
            <FieldRow>
                <FormWrapper label="Project #" style={{ maxWidth: "5em" }}>
                    <StaticTextField
                        value={props.data.projectNumber + "" || ""}
                    />
                </FormWrapper>
                <widgets.siteAddress readOnly />
            </FieldRow>
            {props.data.billingContacts.map((billingContact, index) => (
                <FieldRow key={index}>
                    <FormWrapper label="Billing Contact">
                        <div style={{ display: "flex" }}>
                            <StaticTextField value={billingContact.name} />
                            {billingContact.contact && (
                                <Button
                                    style={{ width: "5em" }}
                                    onClick={() => {
                                        window.open(
                                            "#/contact/edit/" +
                                                billingContact.contact
                                        );
                                    }}
                                >
                                    Open
                                </Button>
                            )}
                        </div>
                    </FormWrapper>
                    <FormWrapper label="Billing Company">
                        <div style={{ display: "flex" }}>
                            <StaticTextField
                                value={
                                    billingContact.company.name ||
                                    "N/A - Personal Residence"
                                }
                            />
                            {billingContact.company.company && (
                                <Button
                                    style={{ width: "5em" }}
                                    onClick={() => {
                                        window.open(
                                            "#/company/edit/" +
                                                billingContact.company.company
                                        );
                                    }}
                                >
                                    Open
                                </Button>
                            )}
                        </div>
                    </FormWrapper>
                    <FormWrapper label="Billing Contact Email">
                        <StaticTextField
                            value={
                                billingContact.company.billingContactEmail ||
                                billingContact.email
                            }
                        />
                    </FormWrapper>
                </FieldRow>
            ))}

            <FieldRow>
                <widgets.customer readOnly label="Client's Name" />
                <widgets.projectDescription label="Project Category" readOnly />
            </FieldRow>

            <FieldRow>
                {props.data.personnel
                    .filter((entry) => entry.role === ROLE_PROJECT_MANAGER)
                    .map((entry) => (
                        <FormWrapper label="Project Manager">
                            <StaticTextField
                                value={
                                    cache.get(USER_META, entry.user)?.name || ""
                                }
                            />
                        </FormWrapper>
                    ))}
            </FieldRow>
            <FieldRow>
                {props.data.personnel
                    .filter((entry) => entry.role === ROLE_CERTIFIED_FOREMAN)
                    .map((entry) => (
                        <FormWrapper label="Certified Foreman">
                            <StaticTextField
                                value={
                                    cache.get(USER_META, entry.user)
                                        ? calcUserCodeName(
                                              cache.get(USER_META, entry.user)!
                                          )
                                        : ""
                                }
                            />
                        </FormWrapper>
                    ))}
            </FieldRow>

            <NewExportButton data={exportData} />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.personnel> &
    WidgetContext<typeof Fields.siteAddress> &
    WidgetContext<typeof Fields.customer> &
    WidgetContext<typeof Fields.projectDescription>;
type ExtraProps = {};
type BaseState = {
    personnel: WidgetState<typeof Fields.personnel>;
    siteAddress: WidgetState<typeof Fields.siteAddress>;
    customer: WidgetState<typeof Fields.customer>;
    projectDescription: WidgetState<typeof Fields.projectDescription>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "PERSONNEL"; action: WidgetAction<typeof Fields.personnel> }
    | { type: "SITE_ADDRESS"; action: WidgetAction<typeof Fields.siteAddress> }
    | { type: "CUSTOMER"; action: WidgetAction<typeof Fields.customer> }
    | {
          type: "PROJECT_DESCRIPTION";
          action: WidgetAction<typeof Fields.projectDescription>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.personnel, data.personnel, cache, "personnel", errors);
    subvalidate(
        Fields.siteAddress,
        data.siteAddress,
        cache,
        "siteAddress",
        errors
    );
    subvalidate(Fields.customer, data.customer, cache, "customer", errors);
    subvalidate(
        Fields.projectDescription,
        data.projectDescription,
        cache,
        "projectDescription",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "PERSONNEL": {
            const inner = Fields.personnel.reduce(
                state.personnel,
                data.personnel,
                action.action,
                subcontext
            );
            return {
                state: { ...state, personnel: inner.state },
                data: { ...data, personnel: inner.data },
            };
        }
        case "SITE_ADDRESS": {
            const inner = Fields.siteAddress.reduce(
                state.siteAddress,
                data.siteAddress,
                action.action,
                subcontext
            );
            return {
                state: { ...state, siteAddress: inner.state },
                data: { ...data, siteAddress: inner.data },
            };
        }
        case "CUSTOMER": {
            const inner = Fields.customer.reduce(
                state.customer,
                data.customer,
                action.action,
                subcontext
            );
            return {
                state: { ...state, customer: inner.state },
                data: { ...data, customer: inner.data },
            };
        }
        case "PROJECT_DESCRIPTION": {
            const inner = Fields.projectDescription.reduce(
                state.projectDescription,
                data.projectDescription,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectDescription: inner.state },
                data: { ...data, projectDescription: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    personnel: function (
        props: WidgetExtraProps<typeof Fields.personnel> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PERSONNEL",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "personnel", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.personnel.component
                state={context.state.personnel}
                data={context.data.personnel}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Personnel"}
            />
        );
    },
    siteAddress: function (
        props: WidgetExtraProps<typeof Fields.siteAddress> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SITE_ADDRESS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "siteAddress", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.siteAddress.component
                state={context.state.siteAddress}
                data={context.data.siteAddress}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Site Address"}
            />
        );
    },
    customer: function (
        props: WidgetExtraProps<typeof Fields.customer> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CUSTOMER",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "customer", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.customer.component
                state={context.state.customer}
                data={context.data.customer}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Customer"}
            />
        );
    },
    projectDescription: function (
        props: WidgetExtraProps<typeof Fields.projectDescription> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_DESCRIPTION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "projectDescription",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectDescription.component
                state={context.state.projectDescription}
                data={context.data.projectDescription}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Description"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let personnelState;
        {
            const inner = Fields.personnel.initialize(
                data.personnel,
                subcontext,
                subparameters.personnel
            );
            personnelState = inner.state;
            data = { ...data, personnel: inner.data };
        }
        let siteAddressState;
        {
            const inner = Fields.siteAddress.initialize(
                data.siteAddress,
                subcontext,
                subparameters.siteAddress
            );
            siteAddressState = inner.state;
            data = { ...data, siteAddress: inner.data };
        }
        let customerState;
        {
            const inner = Fields.customer.initialize(
                data.customer,
                subcontext,
                subparameters.customer
            );
            customerState = inner.state;
            data = { ...data, customer: inner.data };
        }
        let projectDescriptionState;
        {
            const inner = Fields.projectDescription.initialize(
                data.projectDescription,
                subcontext,
                subparameters.projectDescription
            );
            projectDescriptionState = inner.state;
            data = { ...data, projectDescription: inner.data };
        }
        let state = {
            initialParameters: parameters,
            personnel: personnelState,
            siteAddress: siteAddressState,
            customer: customerState,
            projectDescription: projectDescriptionState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    personnel: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.personnel>
    >;
    siteAddress: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.siteAddress>
    >;
    customer: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.customer>
    >;
    projectDescription: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectDescription>
    >;
};
// END MAGIC -- DO NOT EDIT
