import Decimal from "decimal.js";
import { mapValues } from "lodash";
import * as React from "react";
import { useProjectRecordQuery } from "../../../clay/api";
import { Dictionary } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { propCheck } from "../../../clay/propCheck";
import {
    QuickCacheApi,
    useQuickAllRecords,
    useQuickRecord,
} from "../../../clay/quick-cache";
import { OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { RichTextWidget } from "../../rich-text-widget";
import { USER_META } from "../../user/table";
import { ExportDataTable } from "../accounting/ExportDataTable";
import { computeSpectrumItems, SpectrumLine } from "../detail-sheet/phase-code";
import { DETAIL_SHEET_META } from "../detail-sheet/table";
import { MASTER_FORMAT_CODE_META } from "../master-format-codes/table";
import { ExportData, NewExportButton } from "../NewExportButton";
import { SPECTRUM_INVOICE_TYPE_META } from "../spectrum/table";
import { Project, PROJECT_META } from "../table";

export type Data = Project;

export const Fields = {
    iciNotes: OptionalFormField(RichTextWidget),
};

export function usePhaseCodeExport(
    project_id: Link<Project>,
    includeAdded: boolean
) {
    const project = useQuickRecord(PROJECT_META, project_id);
    const detailSheets = useProjectRecordQuery(DETAIL_SHEET_META, project_id);

    const users = useQuickAllRecords(USER_META);
    const masterFormatCodes = useQuickAllRecords(MASTER_FORMAT_CODE_META);
    const spectrumInvoicesTypes = useQuickAllRecords(
        SPECTRUM_INVOICE_TYPE_META
    );

    const rows = React.useMemo(() => {
        if (
            !users ||
            !detailSheets ||
            !masterFormatCodes ||
            !spectrumInvoicesTypes
        ) {
            return undefined;
        }
        return computeSpectrumItems(
            detailSheets,
            users,
            masterFormatCodes,
            spectrumInvoicesTypes,
            includeAdded
        );
    }, [detailSheets, users, masterFormatCodes, spectrumInvoicesTypes, includeAdded]);

    const columns = React.useMemo(() => {
        return {
            Company_Code() {
                return "";
            },
            Job_Number() {
                return "10" + project?.projectNumber?.toString() || "";
            },
            Phase_Code(item) {
                return item.phaseCode;
            },
            Description(item) {
                return item.phaseCodeDescription;
            },
            Cost_Type(item) {
                return item.spectrumInvoiceType;
            },
            Original_Est_Quantity() {
                return "";
            },
            Unit_of_Measure() {
                return "";
            },
            Original_Est_Cost(item) {
                return item.cost.toDecimalPlaces(2);
            },
            Original_Est_Hours(item) {
                return item.hours;
            },
            Bid_Item_Number() {
                return "";
            },
            Alt_Phase_Code() {
                return "";
            },
            Work_Comp_Code() {
                return "";
            },
            Price_Method_Code(item) {
                return item.invoicingType === "fixed"
                    ? "F"
                    : item.invoicingType === "t&m"
                    ? "T"
                    : "U";
            },
            Phase_Cost_Center() {
                return "";
            },
            Current_Est_Hours() {
                return "";
            },
            Current_Est_Quantity() {
                return "";
            },
            Current_Est_Cost() {
                return "";
            },
            Status_Code() {
                return "";
            },
        } satisfies Record<string, (item: SpectrumLine) => string | Decimal>;
    }, [project?.projectNumber]);

    const exportData: ExportData = React.useMemo(() => {
        if (!rows) {
            return {
                errors: ["Not loaded"],
                warnings: [],
                requests: [],
            };
        }
        const errors: string[] = []
        for (const row of rows) {
            if (row.phaseCode.indexOf("?") != -1) {
                errors.push("Invalid phase code: " + row.phaseCode)
            }
        }

        return {
            errors,
            warnings: [],
            requests: [
                {template: "phase",
                request: rows.map((row) =>
                mapValues(columns, (value) => value(row).toString())
            )}],
        };
    }, [rows, columns])

    console.log("EEE", exportData)

    return { rows, columns, exportData };
}

const Keys = {
    Job_Number: false,
    Phase_Code: false,
    Cost_Type: false,
    Original_Est_Cost: true,
    Original_Est_Hours: true,
    Description: false,
    Price_Method_Code: false,
};

function Component(props: Props) {
    const data = usePhaseCodeExport(props.data.id.uuid, true);
    const { rows, columns, exportData } = data;

    return (
        <>
            <ExportDataTable
                rows={rows}
                columns={columns}
                money={new Set(["Original_Est_Cost"])}
                name={`Spectrum Export Phase Codes ${
                    props.data.projectNumber
                } - ${new Date().toString()}.xlsx`}
            />
            <NewExportButton data={exportData} />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.iciNotes>;
type ExtraProps = {};
type BaseState = {
    iciNotes: WidgetState<typeof Fields.iciNotes>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "ICI_NOTES"; action: WidgetAction<typeof Fields.iciNotes> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.iciNotes, data.iciNotes, cache, "iciNotes", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "ICI_NOTES": {
            const inner = Fields.iciNotes.reduce(
                state.iciNotes,
                data.iciNotes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, iciNotes: inner.state },
                data: { ...data, iciNotes: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    iciNotes: function (
        props: WidgetExtraProps<typeof Fields.iciNotes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ICI_NOTES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "iciNotes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.iciNotes.component
                state={context.state.iciNotes}
                data={context.data.iciNotes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Ici Notes"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let iciNotesState;
        {
            const inner = Fields.iciNotes.initialize(
                data.iciNotes,
                subcontext,
                subparameters.iciNotes
            );
            iciNotesState = inner.state;
            data = { ...data, iciNotes: inner.data };
        }
        let state = {
            initialParameters: parameters,
            iciNotes: iciNotesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    iciNotes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.iciNotes>
    >;
};
// END MAGIC -- DO NOT EDIT
