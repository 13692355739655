import React from "react";
import { Alert, Button, ButtonGroup } from "react-bootstrap";
import { rawRequest } from "../../clay/api";
import { useEditableContext } from "../../clay/edit-context";
import { hasPermission } from "../../permissions";
import type { ExportTypes } from "../../server/export";
import { useUser } from "../state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export type ExportRequest<template extends keyof ExportTypes> = {
    template: template,
    request: ExportTypes[template]
}

export type ExportData = {
    warnings: string[];
    errors: string[];
    requests: ExportRequest<keyof ExportTypes>[];
};

export function NewExportButton(props: {
    data: ExportData;
}) {
    const [spectrumErrors, setSpectrumErrors] = React.useState([]);
    const [isRunning, setRunning] = React.useState(false)
    const editable = useEditableContext();
    const user = useUser();

    async function exportToSpectrum() {
        setRunning(true)
        editable.save!.onClick(null, undefined, true);

        const res = await rawRequest({
            type: "EXPORT",
            requests: props.data.requests,
        });
        if (res.errors.length == 0) {
            window.alert("Exported");
        } else {
            setSpectrumErrors(res.errors);
        }
        setRunning(false)
    }

    if (!hasPermission(user, "General", "export-spectrum")) {
        return <></>;
    }

    return (
        <>
            {spectrumErrors.map((x) => (
                <Alert variant="danger">{x}</Alert>
            ))}
            {props.data.errors.map((x) => (
                <Alert variant="danger">{x}</Alert>
            ))}
            {props.data.warnings.map((x) => (
                <Alert variant="warning">{x}</Alert>
            ))}
            <ButtonGroup>
                <Button onClick={exportToSpectrum} disabled={isRunning || props.data.errors.length > 0}>Export to Spectrum

                    {isRunning && <FontAwesomeIcon icon={faSpinner} spin />}
                </Button>
            </ButtonGroup>
        </>
    );
}
